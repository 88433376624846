import React, { memo, useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import classNames from 'classnames';
import { getFeatureFlags } from 'utils/feature-flags';

import { ComponentPreloader } from 'components/ComponentPreloader';
import {
  LoadableFavouritesPage,
  LoadableMyOrdersPage,
  LoadableMyWaitroseHubHomepage,
  LoadableServiceSelection,
  LoadableTrolleyPage,
} from 'components/App/loadableComponents';
import { LoginLink } from 'components/AuthenticationAction/LoginLink';
import HeaderLogo from 'components/HeaderLogo';
import LinksBar from 'components/SiteHeader/LinksBar';
import ConnectedLinksBar from 'components/SiteHeader/LinksBar/ConnectedLinksBar';
import ShoppingSummary from 'components/SiteHeader/ShoppingSummary';
import SearchForm from 'components/Search/SearchForm';
import MobileSearchButton from 'components/SiteHeader/MobileSearchButton';
import SlotButton from 'components/SiteHeader/SlotButton';
import DropDownNav from 'components/SiteHeader/LinksBar/DropDownNav/index';
import DropDownNavHidden from 'components/SiteHeader/LinksBar/DropDownNavHidden';
import { useScrolled } from 'hooks/use-scrolled';

// TODO: [SSR][WARN] Does this impact SSR?
// TODO: migrate to useClientSideMediaQuery or SSRMediaQuery
import { useMediaQuery } from 'react-responsive';
import { SSRMediaQuery } from 'components/SSRMediaQuery';
import { BREAKPOINTS } from 'constants/grid';
import { rawUrls } from 'constants/urls';
import { stickyHeaderOffset } from 'constants/site-header';

import MultiSearchStrip from 'components/MultiSearch/Strip';
import TopNav from 'components/SiteHeader/TopNav';
import MobileOverlay from 'components/SiteHeader/MobileOverlay';
import SlideOutNav from 'components/SiteHeader/SlideOutNav';
import { DeliveryPassStatusProvider } from 'components/BookSlot/contexts/DeliveryPassStatus/DeliveryPassStatusProvider';

import styles from './SiteHeader.scss';

// Ordered according to interaction count * page load speed for each component,
// plus grouped by whether immediately available for navigation, or behind the
// slide out menu.
const nonDesktopPageLoadPreloadComponents = [
  // Site header linked pages
  LoadableTrolleyPage,
  LoadableServiceSelection,
  // Slide out nav linked pages
  LoadableMyWaitroseHubHomepage,
  LoadableMyOrdersPage,
  LoadableFavouritesPage,
];

// the static render is responsible for creating non-interactive version of the site for SSR to pre-render
// for customers to look at whilst we client render the page. Once SSR for auth users is in place, it can be
// removed.
const SiteHeader = memo(
  ({ isLoggedIn, staticRender, clearValue, isOverlayOpen, setClearValue, setMobileOverlay }) => {
    const { renderStaticNavLinks } = getFeatureFlags();
    const [isTablet, setIsTablet] = useState(false);

    const { scrolled } = useScrolled(stickyHeaderOffset);

    const SignInOrTrolley = isLoggedIn ? (
      <ShoppingSummary />
    ) : (
      <LoginLink data-testid="mobileHeaderSigninLink">Sign in</LoginLink>
    );

    const toggleOverlay = () => {
      setClearValue(true);
      setMobileOverlay(!isOverlayOpen);
    };

    const headerRef = useRef();
    const multiSearchRef = useRef();

    const placeholderStyle = useCallback(
      ref => {
        const { current: el } = ref;

        if (!scrolled || !el) return { display: 'none' };

        return { height: el.getBoundingClientRect().height };
      },
      [scrolled],
    );

    const isTabletBreakpoint = useMediaQuery({
      minWidth: BREAKPOINTS.md,
      maxWidth: BREAKPOINTS.lg,
    });
    useEffect(() => {
      setIsTablet(isTabletBreakpoint);
    }, [isTabletBreakpoint]);
    const placeholder = isTablet ? 'Search...' : 'Search groceries...';

    // Menu is rendered at a different position depending on whether in the
    // scrolled bar or not so that the tabbing order is correct
    const menu = (
      <div className={styles.menu} key="desktopmenu">
        {staticRender ? null : <DropDownNav scrolled={scrolled} />}
      </div>
    );

    const mobileHeader = (
      <SSRMediaQuery maxWidth={BREAKPOINTS.md}>
        <ComponentPreloader components={nonDesktopPageLoadPreloadComponents} />
        <div className={classNames('container-fluid', styles.container)}>
          <div className={styles.mobileMenu}>
            <SlideOutNav />
          </div>
          <div className={styles.mobileLogo}>
            <HeaderLogo small />
          </div>
          <div className={styles.inner}>
            <div className={styles.mobileSearch}>
              <MobileSearchButton toggleOverlay={toggleOverlay} />
            </div>
            <div className={styles.mobileSlot}>
              <SlotButton isMobile testId="mobile" textOnly shortTime />
            </div>
            <div className={styles.signInOrTrolley}>{SignInOrTrolley}</div>
          </div>
        </div>
      </SSRMediaQuery>
    );

    const tabletHeader = (
      <SSRMediaQuery minWidth={BREAKPOINTS.md} maxWidth={BREAKPOINTS.lg}>
        <ComponentPreloader components={nonDesktopPageLoadPreloadComponents} />
        <div className={classNames('container-fluid', styles.container)}>
          <div className={styles.mobileMenu}>
            <SlideOutNav />
          </div>
          <div className={styles.mobileLogo}>
            <HeaderLogo small />
          </div>
          <div className={styles.inner}>
            <div className={styles.search}>
              <SearchForm placeholder={placeholder} />
            </div>
            <div className={styles.slot}>
              <SlotButton />
            </div>
            <div className={styles.trolley}>
              <ShoppingSummary />
            </div>
          </div>
        </div>
      </SSRMediaQuery>
    );

    const desktopHeader = (
      <SSRMediaQuery minWidth={BREAKPOINTS.lg}>
        <div className={classNames('container-fluid', styles.container)}>
          <div className={styles.logo}>
            <HeaderLogo />
          </div>
          {scrolled && menu}
          <div className={styles.inner}>
            <div className={styles.search}>
              <SearchForm placeholder={placeholder} />
            </div>
            <div className={styles.slot}>
              <SlotButton />
            </div>
            <div className={styles.trolley}>
              <ShoppingSummary />
            </div>
          </div>
          {!scrolled && menu}
          {scrolled ? null : (
            <div className={styles.links}>
              <ConnectedLinksBar isLoggedIn={isLoggedIn} />
            </div>
          )}
        </div>
      </SSRMediaQuery>
    );

    const connectedHeaderComponents = (
      <DeliveryPassStatusProvider>
        <div className={styles.desktop}>
          {renderStaticNavLinks && <DropDownNavHidden />}
          {scrolled && (
            <div data-testid="DesktopHeaderPlaceholder" style={placeholderStyle(headerRef)} />
          )}
          <div
            className={classNames(styles.desktopHeader, {
              [styles.scrolled]: scrolled,
            })}
            data-testid="DesktopHeader"
            ref={headerRef}
          >
            {mobileHeader}
            {tabletHeader}
            {desktopHeader}
          </div>
        </div>
      </DeliveryPassStatusProvider>
    );

    const staticHeaderComponents = (
      <div className="container-fluid">
        <div className={styles.logo}>
          <HeaderLogo staticRender />
        </div>
        <div className={styles.sticky}>
          <div className={styles.space} />
        </div>

        <div className={styles.links}>
          <LinksBar />
        </div>
      </div>
    );

    return (
      <header className={`${styles.header} no-print`} data-testid="site-header">
        <TopNav staticRender={staticRender} />
        {staticRender ? staticHeaderComponents : connectedHeaderComponents}
        {scrolled && (
          <div
            data-testid="DesktopMultiSearchPlaceholder"
            style={placeholderStyle(multiSearchRef)}
          />
        )}
        {staticRender ? null : (
          <div
            className={classNames(styles.multiSearch, {
              [styles.multiSearchScrolled]: scrolled,
            })}
            ref={multiSearchRef}
            data-testid="MultiSearchWrapper"
          >
            <Routes>
              <Route path={rawUrls.multiSearch} element={<MultiSearchStrip />} />
            </Routes>
          </div>
        )}
        {isOverlayOpen && <MobileOverlay clearValue={clearValue} setClearValue={setClearValue} />}
      </header>
    );
  },
);

SiteHeader.propTypes = {
  isLoggedIn: PropTypes.bool,
  isOverlayOpen: PropTypes.bool,
  staticRender: PropTypes.bool,
  clearValue: PropTypes.bool,
  setClearValue: PropTypes.func,
  setMobileOverlay: PropTypes.func,
};

SiteHeader.defaultProps = {
  isLoggedIn: false,
  staticRender: false,
  clearValue: false,
  isOverlayOpen: false,
  setClearValue: null,
  setMobileOverlay: null,
};

export default SiteHeader;
